import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Bgtwo from '../../assets/images/background/contact-background.jpg'
import * as dayjs from 'dayjs'

import { useNavigate } from "react-router-dom";

function Contact() {

    const navigate = useNavigate();

    return (
        <>
            <div className="testimonials-section" >
                <div className="image-layer" style={{ backgroundImage: `url(${Bgtwo})` }}></div>
                <div className="auto-container">
                    <div className="carousel-box owl-theme">
                        <div className="separator"><span></span><span></span><span></span></div>
                    </div>
                </div>
            </div>

            <section className="reserve-section">
                <div className="auto-container">
                    <div className="outer-box">

                        <div className="row clearfix">
                            <div className="reserv-col col-lg-8 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h1>Online Reservation</h1>
                                        <div className="request-info">Booking request <Link to="#">+84869452656</Link></div>
                                        <div className="request-info">or click Whatsapp &nbsp;
                                            <a target="_blank" href="https://api.whatsapp.com/send?phone=84988360294&text=Hello,%20I%20would%20like%20to%20book%20a%20table">
                                                Whatsapp</a>
                                        </div>
                                        <div>or fill out the order form</div>
                                    </div>

                                    <div class="info">
                                        <div style={{ textAlign: 'center' }}><strong>Thank you for booking with us!</strong></div>
                                        We have received your reservation request and will be in touch shortly to confirm. <br />
                                        Get ready for an amazing experience with us. <br />
                                        <div style={{ textAlign: 'center' }}>See you soon!</div>
                                    </div>
                                    <br />

                                    <div className="form-group col-lg-12 col-md-12 col-sm-12">
                                        <div className="field-inner">

                                            <button
                                                className="theme-btn plausible-event-name=Booking+Click btn-style-one clearfix"
                                                onClick={() => {
                                                    navigate('/')
                                                }}
                                            >
                                                <span className="btn-wrap">
                                                    <span className="text-one">Home</span>
                                                    <span className="text-two">Home</span>
                                                </span>
                                            </button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="info-col col-lg-4 col-md-12 col-sm-12">
                                <div className="inner">
                                    <div className="title">
                                        <h2>Contact Us</h2>
                                    </div>
                                    <div className="data">
                                        <div className="booking-info">
                                            <div className="bk-title">Booking request</div>
                                            <div className="bk-no"><Link to="tel:+84869452656">+84 869452656</Link></div>
                                        </div>
                                        <div className="separator"><span></span></div>
                                        <ul className="info">
                                            <li><strong>Location</strong><br />4th Floor, 20 Trang Tien, Hoan Kiem, Ha Noi </li>
                                            <li><strong>Operating Hours</strong><br />Monday to Sunday <br />05.00 pm - 00.30am</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Contact