import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Footer from '../components/Footer/Main';
import Home from '../components/Home/Main';
import Success from '../components/Menhome/BookingSuccess';


function Index() {

  return (

    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/success" element={<Success />} />
      </Routes>

      <Footer />
    </>

  )
}

export default Index